<template>
    <div style="height: 350px;">
        <a-spin :spinning="loading">
            <a-form :form="form">
                <a-form-item label="旧密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入旧密码" type="password" :value="user.oldPwd" v-decorator="['oldPwd', {rules: ['oldPwd',{required: true, min:6, max: 16, message: '请输入旧密码'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="新密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入新密码" type="password" v-decorator="['newPwd', {rules: ['newPwd',{required: true, min:6, max: 16, message: '请输入新密码'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="重复密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入重复密码" type="password" v-decorator="['reNewPwd', {rules: ['reNewPwd',{required: true, min:6, max: 16, message: '请输入重复密码'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
            </a-form>
            <a-row>
                <a-col :span="24" :style="{ textAlign: 'center' }">
                    <a-button type="primary" @click="postData()">提交</a-button>
                </a-col>
            </a-row>
        </a-spin>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
import md5 from 'js-md5'
export default {
    data () {
        return {
            title: '重置密码',
            loading: false,
            form: this.$form.createForm(this),
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            user: { oldPwd: '', newPwd: '', reNewPwd: '' }
        }
    },
    methods: {
        ...mapActions(['Logout']),
        postData () {
            this.form.validateFields((error, values) => {
                if (!error) {
                    let newPwd = values['newPwd']
                    let reNewPwd = values['reNewPwd']
                    if (newPwd !== reNewPwd) {
                        this.$message.error('两次密码不匹配')
                        return
                    }
                    let params = {
                        oldPwd: md5(values['oldPwd'].trim()),
                        newPwd: md5(newPwd.trim())
                    }
                    this.loading = true
                    this.$api.userAccount.resetPwdBySelf(params).then(res => {
                        if (res.status === 0) {
                            this.handleLogout()
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    }).finally(_ => {
                        this.loading = false
                    })
                }
            })
        },
        handleLogout () {
            let _this = this
            this.$confirm({
                title: '密码修改成功,请退出后重新登录！',
                okText: '确定',
                okType: 'primary',
                cancelText: '取消',
                cancelButtonProps: {
                    props: { disabled: true },
                },
                onOk() {
                    const { Logout } = _this
                    Logout()
                        .then((res) => window.location.href = '/')
                        .catch()
                        .finally(() => {})
                }
            });
        }
    }
}
</script>
