<template>
    <div class="headContainer">
        <a-dropdown placement="bottomRight" v-if="userInfo">
            <span>
                <a-avatar size="large" :src="userInfo.head || defaultLogo" class="head"/>
                <span class="username">{{ userInfo.nickname }}</span>
            </span>
            <template v-slot:overlay>
                <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
                    <a-menu-item key="userCenter" @click="$refs.userCenter.show()">
                        <a-icon type="user" />个人中心
                    </a-menu-item>
                    <a-menu-item key="logout" @click="handleLogout">
                        <a-icon type="logout" />退出登录
                    </a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
        <user-center ref="userCenter"></user-center>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import store from "@/store";
const { mapState, mapActions } = createNamespacedHelpers('user')
import UserCenter from '@/components/userCenter'
export default {
    components: {
        UserCenter
    },
    data () {
        return {
            defaultLogo: require('@/assets/default_logo.png'),
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo
        })
    },
    methods: {
        ...mapActions(['Logout']),
        userCenter () {
            store.commit('app/CHANGE_MENU_ACTION', '/userCenter/UserCenter')
            this.$router.push({ path: '/userCenter/UserCenter' })
        },
        handleLogout () {
            const { Logout } = this
            this.$confirm({
                title: '提示',
                content: '真的要注销登录吗 ?',
                cancelText: '取消',
                okText: '确定',
                onOk () {
                    Logout()
                        .then((res) => {
                            window.location.href = '/'
                            store.commit('app/CHANGE_MENU_ACTION', '/')
                        })
                        .catch()
                        .finally(() => {})
                },
                onCancel () {
                }
            })
        }
    }
}
</script>
<style scoped>
    .headContainer {
        padding-right: 20px;
    }
    .head {
        margin-right: 10px;
        margin-bottom: 5px;
        cursor: pointer;
    }
    .username {
        margin-right: 10px;
        font-size: 16px;
        cursor: pointer;
    }
</style>
