<template>
    <a-layout id="components-layout-demo-custom-trigger" style="min-height: 100vh">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
            <div class="logo" :class="collapsed ? 'collapsed' : 'uncollapsed'">
                <img :src="defaultLogo"/>
                <span :style="collapsed ? 'display: none' : ''">房产管理系统</span>
            </div>
            <!-- 左侧菜单 -->
            <menu-layout></menu-layout>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0">
                <div style="display: flex; justify-items: center; align-items: center;">
                    <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)"/>
                    <breadcrumb style="margin-bottom: 5px;"></breadcrumb>
                    <!-- 占位 -->
                    <div style="flex-grow: 1"></div>
                    <user-menu ></user-menu>
                </div>
            </a-layout-header>
            <a-layout-content
                    :style="{ margin: '12px 12px', padding: '10px', background: '#fff', minHeight: '280px' }">
<!--                <keep-alive>-->
                    <router-view/>
<!--                </keep-alive>-->
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import MenuLayout from './MenuLayout'
import Breadcrumb from '../components/Breadcrumb'
import UserMenu from "@/components/UserMenu";
import { mapState } from 'vuex'
export default {
    components: {
        MenuLayout,
        Breadcrumb,
        UserMenu
    },
    data () {
        return {
            collapsed: false,
            defaultLogo: require('@/assets/default_logo.png'),
            selectedKey: [],
            openKeys: [],
        }
    },
    computed: {
        ...mapState({
            menuTree: state => state.user.authMenuTree,
            schoolInfo: state => state.user.schoolInfo
        })
    },
    methods: {
        menuClick (e) {
            let path = e.key
            this.$router.push({ path: path })
        }
    }
}
</script>
<style scoped>
    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 48px;
        line-height: 48px;
    }
    .logo img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-bottom: 3px;
    }
    .logo span {
        color: white;
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
        line-height: 48px;
    }
    .collapsed {
        text-align: center;
    }
    .uncollapsed {
        padding-left: 10px;
        padding-right: 10px;
    }
</style>
