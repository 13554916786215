<template>
    <a-menu mode="inline" theme="dark" @click="menuClick" :default-selected-keys="[menuAction]" :defaultOpenKeys="openKey()">
        <template v-for="menu in menuTree">
            <a-menu-item v-if="menu.hidden == 0 && menu.type == 2" :key="menu.url">
                <a-icon :type="menu.icon" />
                <span>{{ menu.name }}</span>
            </a-menu-item>
            <sub-menu v-else-if="menu.hidden == 0 && menu.type == 1" :key="menu.url" :menu-info="menu" />
        </template>
    </a-menu>
</template>
<script>
import store from '@/store'
import { mapState } from 'vuex'
import { Menu } from 'ant-design-vue';
const SubMenu = {
    template: `
        <a-sub-menu :key="menuInfo.url" v-bind="$props" v-on="$listeners">
            <span slot="title">
              <a-icon :type="menuInfo.icon" /><span>{{ menuInfo.name }}</span>
            </span>
            <template v-for="item in menuInfo.children">
                <a-menu-item v-if="item.hidden == 0 && item.type == 2" :key="item.url">
                    <a-icon :type="item.icon" />
                    <span>{{ item.name }}</span>
                </a-menu-item>
                <sub-menu v-else-if="item.hidden == 0 && item.type == 1" :key="item.url" :menu-info="item" />
            </template>
        </a-sub-menu>
    `,
    name: 'SubMenu',
    isSubMenu: true,
    props: {
        ...Menu.SubMenu.props,
        menuInfo: {
            type: Object,
            default: () => ({}),
        },
    },
};
export default {
    components: {
        'sub-menu': SubMenu,
    },
    data () {
        return {}
    },
    computed: {
        ...mapState({
            menuTree: state => state.user.authMenuTree,
            menuAction: state => state.app.menuAction,
        })
    },
    mounted () {
        this.$router.push({ path: this.menuAction })
    },
    methods: {
        menuClick (e) {
            let path = e.key
            store.commit('app/CHANGE_MENU_ACTION', path)
            this.$router.push({ path: path })
        },
        openKey () {
            if (this.menuAction === '/') {
                return []
            } else {
                let parentKey = this.menuAction.substring(0, this.menuAction.lastIndexOf('/'))
                return [ parentKey ]
            }
        }
    }
}
</script>
