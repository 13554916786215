<template>
    <div style="height: 350px;">
        <a-spin :spinning="loading">
            <a-form :form="form">
                <a-form-item label="头像" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <upload ref="upload" :length="1" :cutImg="true" :imgs="head" uploadTest="头像" :fixedNumber="[1,1]" @uploadSuccess="headSuccess"></upload>
                </a-form-item>
                <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入用户昵称" v-decorator="['name', {rules: ['name',{required: true, min:2, message: '请输入用户昵称'}],initialValue: formData.name, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="登录账号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入4-16位账号" v-decorator="['account', {rules: ['account',{required: true, min:4, max: 16, message: '请输入4-16位账号'}],initialValue: formData.account, trigger: 'blur'}]"></a-input>
                </a-form-item>
            </a-form>
            <a-row>
                <a-col :span="24" :style="{ textAlign: 'right' }">
                    <a-button type="primary" @click="validData()">提交</a-button>
                </a-col>
            </a-row>
        </a-spin>
    </div>
</template>
<script>
import Upload from "@/components/upload/Upload"
import { createNamespacedHelpers } from 'vuex'
import store from "@/store"
const { mapState } = createNamespacedHelpers('user')
export default {
    components: {
        Upload
    },
    data () {
        return {
            title: '添加用户',
            loading: false,
            form: this.$form.createForm(this),
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            head: [],
            formData: {
                account: '',
                name: '',
                head: ''
            }
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo
        })
    },
    mounted () {
        this.queryUserData()
    },
    methods: {
        queryUserData () {
            this.loading = true
            this.$api.userAccount.findUserData().then(res => {
                if (res.status === 0) {
                    this.formData = res.data
                    let head = res.data.head
                    if (head != null) {
                        let randomNum = Math.floor(Math.random() * 100000)
                        const file = {
                            uid: randomNum + '',
                            name: randomNum + '.png',
                            status: 'done',
                            url: head,
                            response: { data: head }
                        }
                        this.head.push(file)
                    }
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        validData () {
            this.form.validateFields((error, values) => {
                if (!error) {
                    if (this.head.length > 0) {
                        this.formData['head'] = this.head[0].url
                    } else {
                        this.formData['head'] = null
                    }
                    this.formData.name = values['name']
                    this.update()
                }
            })
        },
        headSuccess (e) {
            this.head = e
        },
        update () {
            this.loading = true
            this.$api.userAccount.updateBySelf(this.formData).then(res => {
                this.handlerSuccess(res)
            }).catch(err => {
                this.handlerError(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        handlerSuccess (res) {
            if (res.status === 0) {
                this.$message.success('修改成功')
                this.userInfo.nickname = this.formData.name
                this.userInfo.head = this.formData.head
                console.log('this.userInfo', this.userInfo)
                store.commit('user/USER_CHANGE_USER_INFO', this.userInfo)
            } else {
                this.$message.error(res.msg)
            }
        },
        handlerError (err) {
            this.$message.error(err)
        }
    }
}
</script>
