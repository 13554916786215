<template>
    <a-modal
        title="用户中心"
        :visible.sync="visible"
        :width="1000"
        :footer="null"
        @cancel="close()"
        @ok="validData()"
    >
        <a-tabs default-active-key="1" tab-position="left">
            <a-tab-pane key="1" tab="用户信息">
                <user-data></user-data>
            </a-tab-pane>
            <a-tab-pane key="2" tab="重置密码">
                <reset-pwd></reset-pwd>
            </a-tab-pane>
        </a-tabs>
    </a-modal>
</template>
<script>
import UserData from "@/components/userCenter/modules/UserData"
import ResetPwd from "@/components/userCenter/modules/ResetPwd"
export default {
    components: {
        UserData,
        ResetPwd
    },
    data () {
        return {
            visible: false,
        }
    },
    methods: {
        show () {
          this.visible = true
        },
        close () {
            this.visible = false
        },
        validData () {

        }
    }
}
</script>
